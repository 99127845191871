import { useRef } from 'react';
import { Transition } from 'react-transition-group';

export default function ErrorText({show, text}) {

  const nodeRef = useRef(null);

  const defaultStyle = {
    transition: `opacity 600ms ease-in-out`,
    opacity: 0,
    width: "100%"
  }
  
  const transitionStyles = {
    entering: { opacity: 1 },
    entered:  { opacity: 1 },
    exiting:  { opacity: 0 },
    exited:  { opacity: 0 },
  }

  return (
    <div style={{marginBottom:30}}>
    <Transition timeout={600} in={show} nodeRef={nodeRef}>
    {
      state => (
        <div ref={nodeRef} style={{...defaultStyle, ...transitionStyles[state]}}>
          <div style={{marginLeft: "auto", marginRight: "auto", textAlign: "center"}}>
            <div style={{fontSize: 13, marginLeft: "auto", marginRight: "auto", color: "white", backgroundColor: "#dd0000", border: "1px solid red", borderRadius: 30, padding: "5px 12px", display: "inline"}}>
              {text}
            </div>
          </div>
        </div>
      )
    }
    </Transition>
    </div>
  )
}