import axios from 'axios'
import { Auth } from 'aws-amplify'

export const Networking = {
  // Determine which API/backend we're going to use. This allows for a separate DEV API or mock-up
  // service to help out in localhost / development scenarios.
  apiServer: () => {
    return process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_API
      : process.env.REACT_APP_DEV_API
  },

  // This is a wrapper for API REST calls (POST) that need to be authenticated via AWS Cognito
  _post: (url, data) => {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then((session) => {
          /* The call needs AWS Cognito Credentials */
          axios
            .post(`${Networking.apiServer()}${url}`, data, {
              headers: {
                Authorization: `${session.idToken.jwtToken}`
              }
            })
            .then((res) => {
              resolve(res.data)
            })
            .catch((error) => {
              console.log('Error: ', error)
              reject(error)
            })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // API CALLS ----------------------------------------------------------

  searchFlights: (payload) => {
    return Networking._post(`/v1/searchFlights`, payload)
  },

  recommendFlights: (payload) => {
    return Networking._post(`/v1/recommendFlights`, payload)
  },

  saveOffer: (payload) => {
    return Networking._post(`/v1/saveOffer`, payload)
  },

  getCustomers: () => {
    return Networking._post(`/v1/getCustomers`, '"string"')
  },

  generateTravelerId: (email) => {
    return Networking._post(`/v1/generateId`, { text: email })
  },

  getCabinClasses: () => {
    return Networking._post(`/v1/getCabinClasses`, '""')
  }
}
