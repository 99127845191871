import { useState } from 'react'

import Button from '../components/Button'
import ErrorText from '../components/ErrorText'
import TextInput from '../components/TextInput'
import { CognitoPasswordUpdate } from '../components/Authentication/CognitoPasswordUpdate'

import useStore from '../store'

export const CognitoLoginPage = () => {
  // Global state
  const attemptCognitoLogin = useStore((state) => state.attemptCognitoLogin)
  const clearLoginError = useStore((state) => state.clearLoginError)
  const loginFailed = useStore((state) => state.loginFailed)
  const awsAuthSpecialStepRequired = useStore((state) => state.awsAuthSpecialStepRequired)

  // Local state
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  // Actions
  const signIn = async (e) => {
    e.preventDefault()
    attemptCognitoLogin(username, password)
  }

  // AWS may require us to react to certain challenges. One of those challenges is the NEW PASSWORD REQUIRED
  // Challenge. This fires up if we set up a user in AWS Cognito, and then the user logs in the first time.
  // AWS requires them to set up their first password, so we need to give them a screen to do that.
  if (awsAuthSpecialStepRequired && awsAuthSpecialStepRequired === 'NEW_PASSWORD_REQUIRED') {
    return <CognitoPasswordUpdate />
  }

  return (
    <div
      style={{ width: 400, marginLeft: 'auto', marginRight: 'auto', marginTop: 100, padding: 20 }}>
      <div style={{ textAlign: 'center', fontSize: 18, fontWeight: 'bold', marginBottom: 40 }}>
        <img src="/logo.png" alt="Logo" height="20" />
      </div>

      <ErrorText
        show={loginFailed}
        text="There was an issue trying to sign you in. Please try again."
      />

      <form onSubmit={signIn}>
        <TextInput
          type={'text'}
          label={'Username'}
          name={'username'}
          value={username}
          placeholder="Your Username"
          onWhite={true}
          onChange={(d) => {
            setUsername(d)
            if (loginFailed) clearLoginError()
          }}
        />

        <br />

        <TextInput
          type={'password'}
          label={'Password'}
          name={'password'}
          value={password}
          placeholder="Your Password"
          onWhite={true}
          onChange={(d) => {
            setPassword(d)
            if (loginFailed) clearLoginError()
          }}
        />

        <br />

        <div style={{ textAlign: 'right' }}>
          <Button type="submit" text={'Sign in'} />
        </div>
      </form>
    </div>
  )
}
