import { Tools } from '../../helpers/Tools'
import RouteLabel from '../RouteLabel'
import StopoverPopover from '../StopoverPopover'

import './ClimateOptionRow.css'
/*
  These are the top 4 selection panels/slots on the Climate Options screen. They represent
  the Habit to Beat, Best Match, Better Match and Max Savings.
*/

export default function ClimateOptionRow(props) {
  const co2Improvement = (leg, habitToBeatLeg) => {
    return {
      outwardLeg: Tools.percentageImprovement(leg.climate_impact, habitToBeatLeg.climate_impact),
      returnLeg: Tools.percentageImprovement(leg.climate_impact, habitToBeatLeg.climate_impact),
      trip: Tools.percentageImprovement(leg.climate_impact, habitToBeatLeg.climate_impact)
    }
  }

  const percentageColor = (i) => {
    return <span style={{ color: i >= 0 ? '#DD4155' : '#0a0' }}>{i}%</span>
  }

  const priceImprovement = (leg, habitToBeatLeg) => {
    return Tools.percentageImprovement(leg.price.value, habitToBeatLeg.price.value)
  }

  return (
    <div
      style={{
        borderRadius: 10,
        boxShadow: '0 2px 6px 0 rgba(6,6,93,0.2)',
        marginBottom: 20,
        paddingBottom: 15,
        width: 950,
        position: 'relative'
      }}>
      {/* This is a red x at the right of the flight bundle that allows us to clear up the slot */}
      {props.onClearRow && props.trip && (
        <div
          className="remove-option-row"
          onClick={() => {
            props.onClearRow()
          }}>
          x
        </div>
      )}

      {/* This is the label at the top (HABIT TO BEAT, BEST MATCH, etc.) */}
      <div
        style={{
          position: 'relative',
          left: 0,
          top: 0,
          padding: '7px 15px',
          color: '#fff',
          fontSize: 10,
          letterSpacing: '0.8px',
          fontWeight: 600,
          display: 'inline-block',
          background: props.labelColor
            ? props.labelColor
            : '#0A1EAA',
          borderTopLeftRadius: 8,
          borderBottomRightRadius: 16
        }}>
        {props.label.toUpperCase() || ''}
      </div>

      {/* If we get no results, place an empty container here and end it */}
      {!props.trip && (
        <div style={{ fontSize: 14, padding: 10, marginTop: -15, textAlign: 'center' }}>
          Please pick one from the available flights below.
        </div>
      )}

      {/* This contains the entire roundtrip bundle data (both legs) */}
      {props.trip && (
        <table
          className="climate-options-table"
          style={{ width: 960, paddingLeft: 14, paddingRight: 20 }}
          cellPadding={0}
          cellSpacing={0}>
          {/* OUTBOUND LEG ----------------------------------------------------------- */}
          <tbody>
            <tr>
              {/* Cabin Class */}
              <td style={{ fontSize: 13, width: props.columnWidths[0] }}>              
                {Tools.formatCabinClass(props.trip.outward_itinerary.cabin_class)} (
                {props.trip.outward_itinerary.segments
                  .map((segment) => segment.booking_class)
                  .join(', ')}
                )
              </td>
              {/* Priority */}
              <td style={{ textAlign: 'center', width: props.columnWidths[1] }}>
                {props.trip.outward_itinerary.priority && (
                  <img alt="Priority" src="/check.svg" style={{ marginRight: 3 }} />
                )}
                {!props.trip.outward_itinerary.priority && '-'}
              </td>
              {/* Carrier */}
              <td style={{ fontSize: 13, width: props.columnWidths[2] }}>
                {props.trip.outward_itinerary.segments
                  .map((segment) => {
                    return Tools.figureOutCarrier(segment)
                  })
                  .join(', ')}
              </td>
              {/* Aircraft */}
              <td style={{ fontSize: 13, width: props.columnWidths[3] }}>
                {props.trip.outward_itinerary.segments
                  .map((segment) => segment.aircraft_code)
                  .join(', ')}
              </td>
              {/* Route (LHR>NYC) */}
              <td style={{ width: props.columnWidths[4], fontSize: 13 }}>
                <RouteLabel segments={props.trip.outward_itinerary.segments} />
              </td>
              {/* Departure Time */}
              <td style={{ width: props.columnWidths[5], fontSize: 13 }}>
                {Tools.flightTimeFromDate(
                  Tools.parseServerTime(props.trip.outward_itinerary.segments[0].departure_time)
                )}
              </td>
              {/* Arrival Time */}
              <td style={{ width: props.columnWidths[6], fontSize: 13 }}>
                {Tools.flightTimeFromDate(
                  Tools.parseServerTime(
                    props.trip.outward_itinerary.segments.slice().reverse()[0].arrival_time
                  )
                )}
              </td>
              {/* Duration */}
              <td style={{ width: props.columnWidths[7], fontSize: 13 }}>
                {Tools.minutesToDuration(props.trip.outward_itinerary.duration)}
              </td>
              {/* Stop-Overs */}
              <td style={{ width: props.columnWidths[8], fontSize: 13 }}>
                {props.trip.outward_itinerary.segments.length === 1 ? (
                  'Non-Stop'
                ) : (
                  <StopoverPopover segments={props.trip.outward_itinerary.segments} />
                )}
              </td>
              {/* Climate Impact */}
              <td style={{ width: props.columnWidths[9], fontSize: 13, textAlign: 'right' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'right',
                    alignItems: 'center',
                    paddingRight: 5
                  }}>
                  <div style={{ paddingRight: 5, textAlign: 'right', fontSize: 13 }}>
                    {percentageColor(
                      co2Improvement(
                        props.trip.outward_itinerary,
                        props.habitToBeat.outward_itinerary
                      ).outwardLeg
                    )}
                  </div>
                  <div>{Tools.formatNumber(props.trip.outward_itinerary.climate_impact)}</div>
                </div>
              </td>
              {/* Price */}
              <td rowSpan={2} style={{ width: props.columnWidths[10], textAlign: 'right' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'right',
                    alignItems: 'center',
                    paddingRight: 5
                  }}>
                  <div style={{ paddingRight: 5, textAlign: 'right', fontSize: 13 }}>
                    {percentageColor(priceImprovement(props.trip, props.habitToBeat))}
                  </div>
                  {/* 
                ATTENTION: We are skimming the fractional value off the price
                to save some horizontal space. This is intentional.
                */}
                  <div style={{ fontSize: 13 }}>
                    {Tools.formatNumber(parseInt(props.trip.price.value))}
                  </div>
                </div>
              </td>
              {/* FNZ Points */}
              <td style={{ textAlign: 'right', fontSize: 13, paddingRight: 5 }}>
                {/* We don't specify a width here to take up any space that is still available */}
                {props.trip.outward_itinerary.fnz_points === 0 ? (
                  <span style={{ marginRight: 10 }}>-</span>
                ) : (
                  <span style={{ color: '#39AD63' }}>
                    +{Tools.formatNumber(props.trip.outward_itinerary.fnz_points)}
                  </span>
                )}
              </td>
            </tr>

            {/* RETURN LEG ----------------------------------------------------------- */}
            <tr>
              {/* Cabin Class */}
              <td style={{ fontSize: 13 }}>
                {Tools.formatCabinClass(props.trip.return_itinerary.cabin_class)} (
                {props.trip.return_itinerary.segments
                  .map((segment) => segment.booking_class)
                  .join(', ')}
                )
              </td>
              {/* Priority */}
              <td style={{ textAlign: 'center' }}>
                {props.trip.return_itinerary.priority && (
                  <img alt="Priority" src="/check.svg" style={{ marginRight: 3 }} />
                )}
                {!props.trip.return_itinerary.priority && '-'}
              </td>
              {/* Carrier */}
              <td style={{ fontSize: 13 }}>
                {props.trip.return_itinerary.segments
                  .map((segment) => {
                    return Tools.figureOutCarrier(segment)
                  })
                  .join(', ')}
              </td>
              {/* Aircraft */}
              <td style={{ fontSize: 13 }}>
                {props.trip.return_itinerary.segments
                  .map((segment) => segment.aircraft_code)
                  .join(', ')}
              </td>
              {/* Route (LHR>NYC) */}
              <td style={{ fontSize: 13 }}>
                <RouteLabel segments={props.trip.return_itinerary.segments} />
              </td>
              {/* Departure Time */}
              <td style={{ fontSize: 13 }}>
                {Tools.flightTimeFromDate(
                  Tools.parseServerTime(props.trip.return_itinerary.segments[0].departure_time)
                )}
              </td>
              {/* Arrival Time */}
              <td style={{ fontSize: 13 }}>
                {Tools.flightTimeFromDate(
                  Tools.parseServerTime(
                    props.trip.return_itinerary.segments.slice().reverse()[0].arrival_time
                  )
                )}
              </td>
              {/* Duration */}
              <td style={{ fontSize: 13 }}>
                {Tools.minutesToDuration(props.trip.return_itinerary.duration)}
              </td>
              {/* Stop-Overs */}
              <td style={{ fontSize: 13 }}>
                {props.trip.return_itinerary.segments.length === 1 ? (
                  'Non-Stop'
                ) : (
                  <StopoverPopover segments={props.trip.return_itinerary.segments} />
                )}
              </td>
              {/* Climate Impact */}
              <td style={{ fontSize: 13 }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'right',
                    alignItems: 'center',
                    paddingRight: 5
                  }}>
                  <div style={{ paddingRight: 5, textAlign: 'right', fontSize: 13 }}>
                    {percentageColor(
                      co2Improvement(
                        props.trip.return_itinerary,
                        props.habitToBeat.return_itinerary
                      ).returnLeg
                    )}
                  </div>
                  <div>{Tools.formatNumber(props.trip.return_itinerary.climate_impact)}</div>
                </div>
              </td>

              {/* Price IS INTENTIONALLY MISSING HERE (rowSpan=2, see Outbound leg) */}

              {/* FNZ Points */}
              <td style={{ textAlign: 'right', fontSize: 13, paddingRight: 5 }}>
                {props.trip.return_itinerary.fnz_points === 0 ? (
                  <span style={{ marginRight: 10 }}>-</span>
                ) : (
                  <span style={{ color: '#39AD63' }}>
                    +{Tools.formatNumber(props.trip.return_itinerary.fnz_points)}
                  </span>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  )
}
