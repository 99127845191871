export default function MainContainer(props) {
  return (
    /* We are pushing in the main content by 320px to leave space for the fixed sidebar on the left */
    <main
      style={{
        padding: 40,
        margin: 0,
        marginLeft: 320,
        width: '100%',
        maxWidth: 1110,
        marginRight: 20,
        overflow: 'auto',
        minHeight: '100vh'
      }}>
      {props.children}
    </main>
  )
}
