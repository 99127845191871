import { useState } from 'react'
import { Tools } from '../../helpers/Tools'

import OfferingPricePopover from './OfferingPricePopover'
import StopoverPopover from '../StopoverPopover'

import './OfferCustomizationRow.css'
import RouteLabel from '../RouteLabel'

/*
  These are the top selection panels/slots on the Finalize Offer screen. They represent
  the Habit to Beat, Best Match, Better Match and Max Savings (if available).
*/

export default function OfferCustomizationRow(props) {
  const [priceEditorOpen, setPriceEditorOpen] = useState(false)

  const percentageColor = (i) => {
    return <span style={{ color: i >= 0 ? '#DD4155' : '#0a0' }}>{i}%</span>
  }

  const co2Improvement = () => {
    return {
      outwardLeg: Tools.percentageImprovement(
        props.trip.outward_itinerary.climate_impact,
        props.habitToBeat.outward_itinerary.climate_impact
      ),
      returnLeg: Tools.percentageImprovement(
        props.trip.return_itinerary.climate_impact,
        props.habitToBeat.return_itinerary.climate_impact
      ),
      trip: Tools.percentageImprovement(props.trip.climate_impact, props.habitToBeat.climate_impact)
    }
  }

  const priceImprovement = () => {
    return {
      outwardLeg: Tools.percentageImprovement(
        props.trip.outward_itinerary.price.value,
        props.habitToBeat.outward_itinerary.price.value
      ),
      returnLeg: Tools.percentageImprovement(
        props.trip.return_itinerary.price.value,
        props.habitToBeat.return_itinerary.price.value
      ),
      trip: Tools.percentageImprovement(props.trip.price.value, props.habitToBeat.price.value)
    }
  }

  return (
    <div
      style={{
        borderRadius: 10,
        boxShadow: '0 2px 6px 0 rgba(6,6,93,0.2)',
        marginBottom: 20,
        paddingBottom: 15,
        width: 950,
        position: 'relative'
      }}>
      {/* This is a red x at the right of the flight bundle that allows us to clear up the slot */}
      {props.onClearRow && props.trip && (
        <div
          className="customize-remove-option-row"
          onClick={() => {
            props.onClearRow()
          }}>
          x
        </div>
      )}

      {/* This is the label at the top (HABIT TO BEAT, BEST MATCH, etc.) */}
      <div
        style={{
          position: 'relative',
          left: 0,
          top: 0,
          padding: '7px 15px',
          color: '#fff',
          fontSize: 10,
          letterSpacing: '0.8px',
          fontWeight: 600,
          display: 'inline-block',
          background: props.labelColor
            ? props.labelColor
            : '#0A1EAA',
          borderTopLeftRadius: 8,
          borderBottomRightRadius: 16
        }}>
        {props.label.toUpperCase() || ''}
      </div>

      {/* This contains the entire roundtrip bundle data (both legs) */}
      {props.trip && (
        <div style={{ display: 'flex', alignItems: 'center', width: 950 }}>
          <div>
            {/* Outbound Flight Row ------------------------------------------------------------- */}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: 13,
                letterSpacing: '0.78px',
                paddingLeft: 15,
                marginTop: 10
              }}>
              <div style={{ width: props.columnWidths[0], fontSize: 13 }}>
                {Tools.formatCabinClass(props.trip.outward_itinerary.cabin_class)} (
                {props.trip.outward_itinerary.segments
                  .map((segment) => segment.booking_class)
                  .join(', ')}
                )
              </div>
              <div style={{ width: props.columnWidths[1], textAlign: 'center' }}>
                <input
                  type="checkbox"
                  checked={props.trip.outward_itinerary.priority}
                  onChange={(e) => {
                    props.onPriorityUpdate(
                      'outward_itinerary',
                      !props.trip.outward_itinerary.priority
                    )
                  }}
                />
              </div>
              <div style={{ width: props.columnWidths[2], textAlign: 'center' }}>
                {props.trip.outward_itinerary.segments
                  .map((segment) => {
                    return Tools.figureOutCarrier(segment)
                  })
                  .join(', ')}
              </div>
              <div style={{ width: props.columnWidths[3] }}>
                <RouteLabel segments={props.trip.outward_itinerary.segments} />
              </div>
              <div style={{ width: props.columnWidths[4] }}>
                {Tools.flightTimeFromDate(
                  Tools.parseServerTime(props.trip.outward_itinerary.segments[0].departure_time)
                )}
              </div>
              <div style={{ width: props.columnWidths[5] }}>
                {Tools.flightTimeFromDate(
                  Tools.parseServerTime(
                    props.trip.outward_itinerary.segments.slice().reverse()[0].arrival_time
                  )
                )}
              </div>
              <div style={{ width: props.columnWidths[6] }}>
                {Tools.minutesToDuration(props.trip.outward_itinerary.duration)}
              </div>
              <div style={{ width: props.columnWidths[7] }}>
                {props.trip.outward_itinerary.segments.length === 1 ? (
                  'Non-Stop'
                ) : (
                  <StopoverPopover segments={props.trip.outward_itinerary.segments} />
                )}
              </div>
              <div style={{ width: props.columnWidths[8], textAlign: 'right' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'right',
                    alignItems: 'center'
                  }}>
                  <div style={{ paddingRight: 5, textAlign: 'right', fontSize: 13 }}>
                    {percentageColor(
                      co2Improvement(
                        props.trip.outward_itinerary,
                        props.habitToBeat.outward_itinerary
                      ).outwardLeg
                    )}
                  </div>
                  <div>{Tools.formatNumber(props.trip.outward_itinerary.climate_impact)}</div>
                </div>
              </div>
              <div style={{ width: props.columnWidths[9] }}></div>
              <div
                style={{
                  width: props.columnWidths[10],
                  textAlign: 'right',
                  color: '#0a0'
                }}>
                {props.trip.outward_itinerary.fnz_points === 0 ? (
                  <span style={{ paddingRight: 0 }}>-</span>
                ) : (
                  `+${Tools.formatNumber(props.trip.outward_itinerary.fnz_points)}`
                )}
              </div>
            </div>
            {/* Return Flight Row ------------------------------------------------------------- */}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: 13,
                letterSpacing: '0.78px',
                paddingLeft: 15,
                marginTop: 10
              }}>
              <div style={{ width: props.columnWidths[0], fontSize: 13 }}>
                {Tools.formatCabinClass(props.trip.return_itinerary.cabin_class)} (
                {props.trip.outward_itinerary.segments
                  .map((segment) => segment.booking_class)
                  .join(', ')}
                )
              </div>
              <div style={{ width: props.columnWidths[1], textAlign: 'center' }}>
                <input
                  type="checkbox"
                  checked={props.trip.return_itinerary.priority}
                  onChange={(e) => {
                    props.onPriorityUpdate(
                      'return_itinerary',
                      !props.trip.return_itinerary.priority
                    )
                  }}
                />
              </div>
              <div style={{ width: props.columnWidths[2], textAlign: 'center' }}>
                {props.trip.return_itinerary.segments
                  .map((segment) => {
                    return Tools.figureOutCarrier(segment)
                  })
                  .join(', ')}
              </div>
              <div style={{ width: props.columnWidths[3] }}>
                <RouteLabel segments={props.trip.return_itinerary.segments} />
              </div>
              <div style={{ width: props.columnWidths[4] }}>
                {Tools.flightTimeFromDate(
                  Tools.parseServerTime(props.trip.return_itinerary.segments[0].departure_time)
                )}
              </div>
              <div style={{ width: props.columnWidths[5] }}>
                {Tools.flightTimeFromDate(
                  Tools.parseServerTime(
                    props.trip.return_itinerary.segments.slice().reverse()[0].arrival_time
                  )
                )}
              </div>
              <div style={{ width: props.columnWidths[6] }}>
                {Tools.minutesToDuration(props.trip.return_itinerary.duration)}
              </div>
              <div style={{ width: props.columnWidths[7] }}>
                {props.trip.return_itinerary.segments.length === 1 ? (
                  'Non-Stop'
                ) : (
                  <StopoverPopover segments={props.trip.return_itinerary.segments} />
                )}
              </div>
              <div style={{ width: props.columnWidths[8], textAlign: 'right' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'right',
                    alignItems: 'center'
                  }}>
                  <div style={{ paddingRight: 5, textAlign: 'right', fontSize: 13 }}>
                    {percentageColor(
                      co2Improvement(
                        props.trip.return_itinerary,
                        props.habitToBeat.return_itinerary
                      ).returnLeg
                    )}
                  </div>
                  <div>{Tools.formatNumber(props.trip.return_itinerary.climate_impact)}</div>
                </div>
              </div>
              <div style={{ width: props.columnWidths[9] }}></div>
              <div
                style={{
                  width: props.columnWidths[10],
                  textAlign: 'right',
                  color: '#0a0'
                }}>
                {props.trip.return_itinerary.fnz_points === 0 ? (
                  <span style={{ paddingRight: 0 }}>-</span>
                ) : (
                  `+${Tools.formatNumber(props.trip.return_itinerary.fnz_points)}`
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <div style={{ borderTop: '1px solid #eee', marginTop: 10, marginBottom: 0 }}></div>

      {/* Summary Panel at the bottom right of each flight bundle ----------------------------------------------------- */}

      <div style={{ display: 'flex', justifyContent: 'left' }}>
        {/* This is just the padding on the left side, we need to use the exact column widths
            so that the end lines up perfectly with the last 3 columns. It's annoying, but that's
            what we'll have to deal with for now. */}
        <div
          style={{
            marginLeft: 15,
            width:
              props.columnWidths[0] +
              props.columnWidths[1] +
              props.columnWidths[2] +
              props.columnWidths[3] +
              props.columnWidths[4] +
              props.columnWidths[5] +
              props.columnWidths[6]
          }}></div>
        <div
          style={{
            backgroundColor: '#F8F8FB',
            paddingTop: 15,
            paddingBottom: 15,
            marginBottom: -15,
            flexGrow: 1 /* This horizontally forces the gray background to meet the right corner */,
            borderLeft: '1px solid #eee' /* This 1px needs to be accounted for, see next line... */,
            marginLeft:
              40 /* Move this right by 40 so we can tighten up the gray box a bit */ -
              1 /* That 1px removes the border inset on the left */,
            borderBottomRightRadius: 10,
            display: 'flex',
            alignItems: 'center'
          }}>
          <div
            style={{
              fontWeight: 'bold',
              fontSize: 13,
              paddingLeft: 15,
              width:
                props.columnWidths[7] -
                40 /* We take 40px away here, so we can use marginLeft:40 in the parent component */
            }}>
            Total:
          </div>

          {/* This is the inner container that has the climate impact, roundtrip price and FNZ point numbers
              and each column is the exact same as the columns above it */}
          <div style={{ display: 'flex' }}>
            <div style={{ width: props.columnWidths[8], textAlign: 'right', fontSize: 13 }}>
              {props.habitToBeat !== props.trip && (
                <div
                  style={{
                    fontWeight: 'bold',
                    fontSize: 13,
                    marginBottom: 1,
                    textAlign: 'right'
                  }}>
                  {percentageColor(co2Improvement().trip)}{' '}
                </div>
              )}
              <div style={{ paddingTop: 5, paddingBottom: 5, textAlign: 'right' }}>
                {Tools.formatNumber(props.trip.climate_impact)}
              </div>
            </div>
            <div style={{ width: props.columnWidths[9], textAlign: 'right', fontSize: 13 }}>
              <OfferingPricePopover
                price={props.trip.price.value}
                currency={props.trip.price.currency}
                priceImprovement={props.habitToBeat !== props.trip ? priceImprovement().trip : null}
                onChange={(p) => {
                  props.onPriceUpdate(p)
                  setPriceEditorOpen(false)
                }}
                onOpen={() => setPriceEditorOpen(true)}
                onClose={() => setPriceEditorOpen(false)}
                isOpen={priceEditorOpen}
              />
            </div>
            <div
              style={{
                width: props.columnWidths[10],
                textAlign: 'right',
                fontSize: 13,
                color: props.habitToBeat !== props.trip ? '#0a0' : '#666',
                alignSelf: 'center'
              }}>
              {props.habitToBeat === props.trip && '0'}
              {props.habitToBeat !== props.trip && `+${Tools.formatNumber(props.trip.fnz_points)}`}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
