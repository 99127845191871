import './Button.css';
export default function Button(props) {
  return (
    <button 
      type={props.type || "button"} 
      className={`button-component ${props.disabled ? 'button-disabled' : ''} ${props.large ? 'button-large' : ''}`} 
      onClick={() => { 
        if (props.onClick) {
          if (!props.disabled) {
            props.onClick() 
          }
        }
      }}>
      {props.text}
    </button>
  )
}