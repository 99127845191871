export default function RouteLabel(props) {
  if (props.segments && props.segments.length > 0) {
    return (
      <>
        {props.segments[0].origin}
        <span style={{ color: '#aaa', fontSize: 11, marginLeft: 2, marginRight: 3 }}>→</span>
        {props.segments[props.segments.length - 1].destination}
      </>
    )
  } else {
    return null
  }
}
