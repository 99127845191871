import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useStore from '../store'
import './SidebarStatus.css'

export default function SidebarStatus(props) {
  const jumpToWizardStage = useStore((state) => state.jumpToWizardStage)
  const states = ['Habit to Beat', 'Climate Options', 'Offering']

  return (
    <div>
      {states.map((status, i) => {
        return (
          <div
            key={`status_${i}`}
            onClick={() => {
              if (i < props.state) {
                jumpToWizardStage(i)
              }
            }}
            style={{
              cursor: i < props.state ? 'pointer' : 'default',
              display: 'flex',
              padding: 15,
              marginBottom: 8,
              borderRadius: 10,
              backgroundColor: props.state === i ? '#f8f8f8' : 'transparent',
              color: props.state === i ? '#08085D' : '#CDCDDF',
              fontWeight: props.state === i ? 600 : 400
            }}>
            <div>{status}</div>
            <div style={{ flexGrow: 1 }}></div>
            {i < props.state && (
              <div>
                <FontAwesomeIcon className="green-check" icon="check" size="xs" />
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}
