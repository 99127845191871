export default function SortIcon(props) {
  var icon = '/no-sort.png'
  if (props.direction && props.direction === 1) icon = 'sort-up.png'
  if (props.direction && props.direction === -1) icon = 'sort-down.png'
  return (
    <div style={{ marginLeft: 5, marginTop: 5 }}>
      <img src={icon} style={{ width: 10 }} alt="sort" />
    </div>
  )
}
