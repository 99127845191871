import { Auth } from 'aws-amplify'
export const User = {
  get: () => {
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
      .then((user) => {
        resolve(user)
      })
      .catch((error) => { 
        reject(error)
      })
    })
  }
}