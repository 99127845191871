import './CabinClassField.css';
export default function CabinClassField(props) {
  return (
    <div>
      <div className="cabinclass-label">{props.label||"Cabin Class"}</div>
      <select className='cabinclass-component' onChange={(e) => { props.onChange(e.target.value) }} value={props.cabinClass}>
        {
          props.availableClasses.map((c,i) => {
            return (
              <option key={`class_option_${i}`} value={c.acronym}>{c.description.replace(" Class", "")}</option>
            )
          })
        }
      </select>
    </div>
  )
}