import { useState } from 'react'
import { Tools } from '../../helpers/Tools'
import RouteLabel from '../RouteLabel'
import StopoverPopover from '../StopoverPopover'
import ClimateOptionActionPopover from './ClimateOptionActionPopover'
import './ClimateOptionFlightList.css'

export default function ClimateOptionFlightList(props) {
  const [visiblePopover, setVisiblePopover] = useState(-1)

  const co2Improvement = (leg, habitToBeatLeg) => {
    return {
      outwardLeg: Tools.percentageImprovement(leg.climate_impact, habitToBeatLeg.climate_impact),
      returnLeg: Tools.percentageImprovement(leg.climate_impact, habitToBeatLeg.climate_impact),
      trip: Tools.percentageImprovement(leg.climate_impact, habitToBeatLeg.climate_impact)
    }
  }

  const percentageColor = (i) => {
    return <span style={{ color: i >= 0 ? '#DD4155' : '#0a0' }}>{i}%</span>
  }

  const priceImprovement = (leg, habitToBeatLeg) => {
    return Tools.percentageImprovement(leg.price.value, habitToBeatLeg.price.value)
  }

  if (!props.flights || props.flights.length === 0) {
    return (
      <div style={{ padding: 20, fontSize: 14, color: '#666' }}>
        Sorry, there are no available flights for this cabin class category.
      </div>
    )
  }

  const tableColumnWidths = [
    70, // Booking Class
    75, // Priority
    75, // Carrier
    95, // Route
    55, // Departs
    55, // Arrives
    80, // Duration
    75, // Stops
    110, // Climate Impact
    90, // Round Trip Fare
    70 // FNZ Points
  ]

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'left',
          fontSize: 13,
          letterSpacing: '0.5px',
          fontWeight: 'bold',
          marginTop: 10,
          marginLeft: 10
        }}>
        <div style={{ width: tableColumnWidths[0] }}>Booking Class</div>
        <div style={{ width: tableColumnWidths[1], textAlign: 'center' }}>Priority</div>
        <div style={{ width: tableColumnWidths[2] }}>Carrier</div>
        <div style={{ width: tableColumnWidths[3] }}>Route</div>
        <div style={{ width: tableColumnWidths[4] }}>DEP</div>
        <div style={{ width: tableColumnWidths[5] }}>ARR</div>
        <div style={{ width: tableColumnWidths[6] }}>Duration</div>
        <div style={{ width: tableColumnWidths[7] }}>Stops</div>
        <div style={{ width: tableColumnWidths[8], textAlign: 'right' }}>
          Climate Impact
          <br />
          <span style={{ color: '#aaa', fontSize: 10 }}>kg CO2e</span>
        </div>
        <div style={{ width: tableColumnWidths[9], textAlign: 'right' }}>
          Round Trip
          <br />
          <span style={{ color: '#aaa', fontSize: 10 }}>CHF</span>
        </div>
        <div style={{ width: tableColumnWidths[10], textAlign: 'right' }}>C-Points</div>
      </div>
      <div>
        {props.flights &&
          props.flights.length > 0 &&
          props.flights.map((trip, tripIndex) => {
            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: 10,
                  borderRadius: 10,
                  boxShadow: '0 2px 6px 0 rgba(6,6,93,0.2)',
                  marginTop: 10,
                  marginBottom: 5,
                  paddingTop: 5
                }}
                key={`flight_${tripIndex}`}>
                {/* Flight Roundtrip */}
                <table
                  className="climate-options-flight-list-table"
                  style={{ width: 950 }}
                  cellPadding={0}
                  cellSpacing={0}>
                  {/* Outbound Leg ------------------------------------------------------------- */}
                  <tr>
                    {/* Booking Class */}
                    <td style={{ fontSize: 13, width: tableColumnWidths[0] }}>
                      {trip.outward_itinerary.segments
                        .map((segment) => segment.booking_class)
                        .join(', ')}
                    </td>
                    {/* Priority */}
                    <td style={{ width: tableColumnWidths[1], textAlign: 'center' }}>
                      {trip.outward_itinerary.priority && (
                        <img alt="Priority" src="/check.svg" style={{ marginRight: 3 }} />
                      )}
                      {!trip.outward_itinerary.priority && '-'}
                    </td>
                    {/* Carrier */}
                    <td style={{ fontSize: 13, width: tableColumnWidths[2] }}>
                      {trip.outward_itinerary.segments
                        .map((segment) => {
                          return Tools.figureOutCarrier(segment)
                        })
                        .join(', ')}
                    </td>
                    {/* Route */}
                    <td style={{ fontSize: 13, width: tableColumnWidths[3] }}>
                      <RouteLabel segments={trip.outward_itinerary.segments} />
                    </td>
                    {/* DEP */}
                    <td style={{ fontSize: 13, width: tableColumnWidths[4] }}>
                      {Tools.flightTimeFromDate(
                        Tools.parseServerTime(trip.outward_itinerary.segments[0].departure_time)
                      )}
                    </td>
                    {/* ARR */}
                    <td style={{ fontSize: 13, width: tableColumnWidths[5] }}>
                      {Tools.flightTimeFromDate(
                        Tools.parseServerTime(
                          trip.outward_itinerary.segments.slice().reverse()[0].arrival_time
                        )
                      )}
                    </td>
                    {/* Duration */}
                    <td style={{ fontSize: 13, width: tableColumnWidths[6] }}>
                      {Tools.minutesToDuration(trip.outward_itinerary.duration)}
                    </td>
                    {/* Stops */}
                    <td style={{ fontSize: 13, width: tableColumnWidths[7] }}>
                      {trip.outward_itinerary.segments.length === 1 ? (
                        'Non-Stop'
                      ) : (
                        <StopoverPopover segments={trip.outward_itinerary.segments} />
                      )}
                    </td>
                    {/* Climate Impact */}
                    <td style={{ fontSize: 13, width: tableColumnWidths[8] }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'right',
                          alignItems: 'center'
                        }}>
                        <div style={{ paddingRight: 5, textAlign: 'right', fontSize: 13 }}>
                          {percentageColor(
                            co2Improvement(
                              trip.outward_itinerary,
                              props.habitToBeat.outward_itinerary
                            ).outwardLeg
                          )}
                        </div>
                        <div>{Tools.formatNumber(trip.outward_itinerary.climate_impact)}</div>
                      </div>
                    </td>
                    {/* Round Trip Price */}
                    <td rowSpan={2} style={{ width: tableColumnWidths[9], textAlign: 'right' }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'right',
                          alignItems: 'center'
                        }}>
                        <div style={{ paddingRight: 5, textAlign: 'right', fontSize: 13 }}>
                          {percentageColor(priceImprovement(trip, props.habitToBeat))}
                        </div>
                        {/* 
                        ATTENTION: We are skimming the fractional value off the price
                        to save some horizontal space. This is intentional.
                        */}
                        <div style={{ fontSize: 13 }}>
                          {Tools.formatNumber(parseInt(trip.price.value))}
                        </div>
                      </div>
                    </td>
                    {/* FNZ Points */}
                    <td
                      style={{
                        textAlign: 'right',
                        fontSize: 13,
                        width: tableColumnWidths[10]
                      }}>
                      {/* We don't specify a width here to take up any space that is still available */}
                      {trip.outward_itinerary.fnz_points === 0 ? (
                        <span style={{ marginRight: 10 }}>-</span>
                      ) : (
                        <span style={{ color: '#39AD63' }}>
                          +{Tools.formatNumber(trip.outward_itinerary.fnz_points)}
                        </span>
                      )}
                    </td>
                    {/* ACTION MENU */}
                    <td rowSpan={2} style={{ textAlign: 'right' }}>
                      <ClimateOptionActionPopover
                        isOpen={visiblePopover === tripIndex}
                        onClose={() => {
                          setVisiblePopover(-1)
                        }}
                        onOpen={() => {
                          setVisiblePopover(tripIndex)
                        }}
                        onSelect={(promoteTo) => {
                          props.onPromote(trip, promoteTo)
                        }}
                      />
                    </td>
                  </tr>
                  {/* Return Leg -------------------------------------------------------------  */}
                  <tr>
                    {/* Booking Class */}
                    <td style={{ fontSize: 13 }}>
                      {trip.return_itinerary.segments
                        .map((segment) => segment.booking_class)
                        .join(', ')}
                    </td>
                    {/* Priority */}
                    <td style={{ textAlign: 'center' }}>
                      {trip.return_itinerary.priority && (
                        <img alt="Priority" src="/check.svg" style={{ marginRight: 3 }} />
                      )}
                      {!trip.return_itinerary.priority && '-'}
                    </td>
                    {/* Carrier */}
                    <td style={{ fontSize: 13 }}>
                      {trip.return_itinerary.segments
                        .map((segment) => {
                          return Tools.figureOutCarrier(segment)
                        })
                        .join(', ')}
                    </td>
                    {/* Route */}
                    <td style={{ fontSize: 13 }}>
                      <RouteLabel segments={trip.return_itinerary.segments} />
                    </td>
                    {/* DEP */}
                    <td style={{ fontSize: 13 }}>
                      {Tools.flightTimeFromDate(
                        Tools.parseServerTime(trip.return_itinerary.segments[0].departure_time)
                      )}
                    </td>
                    {/* ARR */}
                    <td style={{ fontSize: 13 }}>
                      {Tools.flightTimeFromDate(
                        Tools.parseServerTime(
                          trip.return_itinerary.segments.slice().reverse()[0].arrival_time
                        )
                      )}
                    </td>
                    {/* Duration */}
                    <td style={{ fontSize: 13 }}>
                      {Tools.minutesToDuration(trip.return_itinerary.duration)}
                    </td>
                    {/* Stops */}
                    <td style={{ fontSize: 13 }}>
                      {trip.return_itinerary.segments.length === 1 ? (
                        'Non-Stop'
                      ) : (
                        <StopoverPopover segments={trip.return_itinerary.segments} />
                      )}
                    </td>
                    {/* Climate Impact */}
                    <td style={{ fontSize: 13 }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'right',
                          alignItems: 'center'
                        }}>
                        <div style={{ paddingRight: 5, textAlign: 'right', fontSize: 13 }}>
                          {percentageColor(
                            co2Improvement(
                              trip.return_itinerary,
                              props.habitToBeat.return_itinerary
                            ).returnLeg
                          )}
                        </div>
                        <div>{Tools.formatNumber(trip.return_itinerary.climate_impact)}</div>
                      </div>
                    </td>
                    {/* Round Trip Price -- INTENTIONALLY LEFT OUT HERE (rowSpan=2) */}
                    {/* FNZ Points */}
                    <td
                      style={{
                        textAlign: 'right',
                        fontSize: 13
                      }}>
                      {/* We don't specify a width here to take up any space that is still available */}
                      {trip.return_itinerary.fnz_points === 0 ? (
                        <span style={{ marginRight: 10 }}>-</span>
                      ) : (
                        <span style={{ color: '#39AD63' }}>
                          +{Tools.formatNumber(trip.return_itinerary.fnz_points)}
                        </span>
                      )}
                    </td>
                    {/* ACTION MENU -- INTENTIONALLY LEFT OUT HERE (rowSpan=2)  */}
                  </tr>
                </table>
              </div>
            )
          })}
      </div>
    </>
  )
}
