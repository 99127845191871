import { useCallback, useState } from 'react'
import { Tools } from '../helpers/Tools'
import './TextInput.css'
export default function TextInput(props) {

  let debounceTime = 200;
  let debouncer = props.debounce || false;
  const [delayedOnChange, setDelayedOnChange] = useState(null);
  const [lastChangeMade, setLastChangeMade] = useState(0);
  const [localCopyBeforeDebounceHits, setLocalCopy] = useState(props.value||"");

  // We need this little helper to make auto-focus work on the input
  // when they're placed inside of Popups and similar elements.
  const callbackRef = useCallback(
    (inputElement) => {
      if (inputElement && props.autoFocus) {
        setTimeout(() => {
          inputElement.focus()
          inputElement.select()
        }, 250)
      }
    },
    [props.autoFocus]
  )

  // If debouncing is enabled, this helps us do that  
  Tools.useInterval(() => {
    if (debouncer) {
      // Do we even have something waiting to be sent out?
      if (delayedOnChange !== null) {
        // Okay, we do, so is it time yet?
        if ((new Date().getTime()) - lastChangeMade > debounceTime) {
          props.onChange(delayedOnChange);
          setDelayedOnChange(null);
          setLastChangeMade(null);
        }
      }
    }
  }, 500)

  // Return our customized input field
  return (
    <div>
      {props.label && props.label.length > 0 && (
        <div style={{ fontSize: 12, color: '#333', marginBottom: 5 }}>{props.label}</div>
      )}
      <input
        ref={callbackRef}
        autoFocus={props.autoFocus || false}
        className="text-input"
        style={{
          ...(props.style || {}),
          paddingLeft: props.onWhite ? 0 : 10,
          borderBottom: props.onWhite ? '1px solid #ddd' : 'none',
          textAlign: props.textAlign ? props.textAlign : 'left'
        }}
        name={props.name || ''}
        type={props.type || 'text'}
        placeholder={props.placeholder}
        value={debouncer ? localCopyBeforeDebounceHits : props.value}
        onChange={(e) => {
          if (debouncer) {
            setDelayedOnChange(e.target.value)
            setLastChangeMade(new Date().getTime())
            setLocalCopy(e.target.value); // This is so we can already show it in the box, but not tell the "parent" about it
          } else {
            props.onChange(e.target.value)
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && props.onEnter) props.onEnter()
        }}
      />
    </div>
  )
}
