import { useState } from 'react'
import Button from '../../components/Button'
import ErrorText from '../../components/ErrorText'
import FlightBundleResult from '../../components/FlightBundleResult'
import FlightSearchBox from '../../components/FlightSearchComponents/FlightSearchBox'
import TextInput from '../../components/TextInput'
import { Networking } from '../../helpers/Networking'
import { Tools } from '../../helpers/Tools'

import useStore from '../../store'

export default function WizardSearch(props) {

  // Global State
  const agencyClient = useStore((state) => state.agencyClient)
  const travelerId = useStore((state) => state.travelerId)
  const flightResults = useStore((state) => state.flightResults)
  const habitToBeat = useStore((state) => state.habitToBeat)
  const setSearchId = useStore((state) => state.setSearchId)
  const setHabitToBeat = useStore((state) => state.setHabitToBeat)
  const setFlightResults = useStore((state) => state.setFlightResults)

  // This contains all the airports and similar things
  const setDictionary = useStore((state) => state.setDictionary)

  // Local State
  const [isLoading, setLoading] = useState(false)
  const [queryFailed, setQueryFailed] = useState(false)
  const [noResultsFound, setNoResultsFound] = useState(false)
  const [textFilter, setTextFilter] = useState("");
  const [resultLimit, setResultLimit] = useState(20);

  // Default Search
  const [searchDetails, updateSearchDetails] = useState({
    origin: '',
    destination: '',
    departure_date: Tools.getShortFormatDate(
      new Date(new Date().getTime() + 86400000 * 14)
    ) /* 2 weeks out */,
    departure_time: '07:00:00',
    return_date: Tools.getShortFormatDate(
      new Date(new Date().getTime() + 86400000 * 17)
    ) /* 2 weeks out + 3 days later */,
    return_time: '18:00:00',
    cabin_class: 'ECONOMY',
    max_stops: 0
  })

  // Not really a pagination, more of a cut off to have a quick render
  // with the option to extend to the full list if needed
  const paginateFlightResults = (flights, limit) => {
    return (limit <= 0) ? flights : flights.slice(0, limit)
  }

  const filterFlightResults = (flights, textFilter) => {
    let filteredData = [];
    // Require at least 2 characters to start filtering
    if (textFilter && textFilter.length > 1) {
      console.log("[ACTIVE] Filtering by:", textFilter)
      flights.forEach((flight) => {
        var includeScore = 0; // The score will help us later rank the most matching results first

        // Let's look at all flight segments combined of this bundle, to see what we can match in there
        flight.outward_itinerary.segments.concat(flight.return_itinerary.segments).forEach((segment) => {
          if (segment && (Tools.figureOutCarrier(segment)||"---").indexOf(textFilter.toUpperCase())>=0) includeScore++;
          if (segment && segment.origin && segment.origin.indexOf(textFilter.toUpperCase())>=0) includeScore++;
          if (segment && segment.destination && segment.destination.indexOf(textFilter.toUpperCase())>=0) includeScore++;
          if (segment && segment.flight_number && segment.flight_number.toString().indexOf(textFilter.toUpperCase())>=0) includeScore++;
          if (segment && segment.aircraft_code && segment.aircraft_code.toString().indexOf(textFilter.toUpperCase())>=0) includeScore++;

          // Also look for combinations, like carrier and flight number
          if (segment && segment.flight_number) { // Make sure we have the necessary data
            let flightAndCarrier1 = Tools.figureOutCarrier(segment) + " " + segment.flight_number.toString()
            let flightAndCarrier2 = Tools.figureOutCarrier(segment) + "" + segment.flight_number.toString()
            if (textFilter.trim().toUpperCase() === flightAndCarrier1.toUpperCase()) includeScore++;
            if (textFilter.trim().toUpperCase() === flightAndCarrier2.toUpperCase()) includeScore++;
          }
        })

        if (includeScore>0) filteredData.push(flight);
      })
      return filteredData;
    }
    return flights;
  }

  const parseFlightResults = (data) => {
    if (Object.keys(data.result.connections.roundtrip_bundles).length === 0) {
      setFlightResults([])
      setSearchId(data.result.id)
      setNoResultsFound(true)
    } else {
      setNoResultsFound(false)
    }

    var roundtrips = []
    try {
      // Iterature through all cabin classes and concat all the results into our roundtrips collection
      Object.keys(data.result.connections.roundtrip_bundles).forEach((classOptions, i) => {
        roundtrips = roundtrips.concat(data.result.connections.roundtrip_bundles[classOptions])
      })
    } catch (e) {
      console.log(e)
    }

    // Prepare the dictionary first so it is ready when we'll need it
    setDictionary(data.result.dictionaries)

    // Okay, tell the state that we've got new flights ready to go!
    setFlightResults(roundtrips)

    // Let the wizard know what our search ID is. This ID will be used for
    // all subsequent requests, so that the backend knows what session we're talking about.
    setSearchId(data.result.id)
  }

  const launchSearch = () => {
    setLoading(true)
    setFlightResults([])
    setQueryFailed(false)
    setNoResultsFound(false)

    Networking.searchFlights({
      customer_id: agencyClient ? agencyClient.id : 0,
      traveler_id: travelerId.toString(),
      search_query: searchDetails
    })
      .then((data) => {
        parseFlightResults(data)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setQueryFailed(true)
        setLoading(false)
      })
  }

  // Calculate filtered results
  let filteredResults = filterFlightResults(flightResults, textFilter) || [];

  return (
    <div>
      <h1 style={{ marginBottom: 50 }}>Habit to Beat</h1>

      <FlightSearchBox
        details={searchDetails}
        onUpdateSearch={(newDetails) => {
          updateSearchDetails(newDetails)
        }}
        onSearch={launchSearch}
        isLoading={isLoading}
      />

      <div style={{ marginTop: 100, maxWidth: 860, display: queryFailed ? 'block' : 'none' }}>
        <ErrorText
          show={queryFailed}
          text="There was an issue trying to query the flight engine. Please try again."
        />
      </div>

      {noResultsFound && (
        <div style={{ padding: 20, marginTop: 100, textAlign: 'center' }}>
          Sorry, there are no available flights for this cabin class or time-frame.
        </div>
      )}

      {/* Just for now: we're adding a SECOND continue button up here (before results), so
          we don't confuse the user too much with the Search Flights button still being visible. */}
      {flightResults.length > 0 && (
        <div
          style={{
            marginTop: 50,
            borderTop: '1px solid #ddd',
            borderBottom: '1px solid #ddd',
            paddingTop: 20,
            paddingBottom: 20,
            paddingRight: 20,
            textAlign: 'right',
            maxWidth: 875,
            fontSize: 14,
            color: '#666'
          }}>
          You can move to the next step once you've selected a flight &nbsp; &nbsp; &nbsp;
          <Button disabled={!habitToBeat} text="Continue" onClick={props.onCompleted} />
        </div>
      )}
      
      { flightResults && flightResults.length > 0 && 
      <div style={{padding: 20, paddingLeft: 0, maxWidth: 240}}>
        <TextInput
          style={{width: 100}}
          debounce={true}
          label={"Filter results by airline, aircraft or IATA"}
          type={"text"}
          value={textFilter} 
          placeholder="Type to filter results..." 
          onWhite={true}
          onChange={(d)=>{ setTextFilter(d); }}
        />
      </div>
      }

      {flightResults.length > 0 && (
        <div style={{ marginTop: 10 }}>
          <FlightBundleResult results={
              paginateFlightResults(filteredResults, resultLimit)
            } 
            onResultPicked={setHabitToBeat} />
          <br />
          {!!resultLimit && resultLimit<filteredResults.length && 
          <div style={{textAlign:"center", fontSize: 13, color: "#666", width: 875, marginBottom: 20}}>
            We are only showing the top-most results. <span style={{cursor:"pointer", textDecoration:"underline"}} onClick={()=>{setResultLimit(0)}}>Show all {filteredResults.length} results</span>
          </div>}
          <br />
          <Button disabled={!habitToBeat} text="Continue" onClick={props.onCompleted} />
        </div>
      )}
    </div>
  )
}
