import useStore from '../store'

import Sidebar from '../components/Sidebar'
import MainContainer from '../components/MainContainer'
import PageContainer from '../components/PageContainer'

import WizardClimateOptions from './wizard/ClimateOptions'
import WizardOffer from './wizard/Offer'
import WizardSearch from './wizard/Search'
import WizardShare from './wizard/Share'

export default function BookingWizard() {
  const wizardStage = useStore((state) => state.wizardStage)
  const nextWizardStage = useStore((state) => state.nextWizardStage)
  const resetWizard = useStore((state) => state.resetWizard)

  const renderWizardStage = (stage) => {
    switch (stage) {
      case 0:
        return <WizardSearch onCompleted={nextWizardStage} />
      case 1:
        return <WizardClimateOptions onCompleted={nextWizardStage} />
      case 2:
        return <WizardOffer onCompleted={nextWizardStage} />
      case 3:
        return <WizardShare onCompleted={resetWizard} />
      default:
        return (
          <div>Sorry, we ran into a strange place. Please refresh the page and start over.</div>
        )
    }
  }

  return (
    <PageContainer>
      <Sidebar stage={wizardStage} />
      <MainContainer>{renderWizardStage(wizardStage)}</MainContainer>
    </PageContainer>
  )
}
