import { useState } from 'react'

import { Tools } from '../../helpers/Tools'
import SearchButton from '../SearchButton'
import Loading from '../Loading'
import AirportSearchField from './AirportSearchField'
import CabinClassField from './CabinClassField'
import FlightDateField from './FlightDateField'
import FlightTimeField from './FlightTimeField'
import StopoverField from './StopoverField'

import useStore from '../../store'

import './FlightSearchBox.css'

export default function FlightSearchBox(props) {
  const availableCabinClasses = useStore((state) => state.availableCabinClasses)
  const [isDeparturePickerOpen, setDeparturePickerOpen] = useState(false)
  const [isReturnPickerOpen, setReturnPickerOpen] = useState(false)

  return (
    <div style={{ width: 'auto', display: 'inline-block' }}>
      <div className="flightSearchBox">

        {/* DEPARTURE DETAILS ========================================== */}

        <div style={{ display: 'flex' }}>
          <AirportSearchField
            label="Departure from"
            iata={props.details.origin}
            onChange={(iata) => {
              props.onUpdateSearch({ ...props.details, origin: iata })
            }}
          />

          <div className="hSeparator"></div>

          <FlightDateField
            isOpen={isDeparturePickerOpen}
            whenOpened={() => {
              setDeparturePickerOpen(true)
              setReturnPickerOpen(false)
            }}
            whenClosed={() => {
              setDeparturePickerOpen(false)
            }}
            label="Departure Date"
            date={props.details.departure_date}
            minimumDate={new Date()}
            onChange={(newDate) => {
              /* 
                Important: If the return date is EARLIER than this new departure date, 
                 then we'll need to move the return date forward to the same day at the very least
              */
              if (props.details.return_date && props.details.return_date < newDate) {
                props.onUpdateSearch({ ...props.details, departure_date: newDate, return_date: newDate })
              } else {
                props.onUpdateSearch({ ...props.details, departure_date: newDate })
              }
            }}
          />

          <FlightTimeField
            hour={props.details.departure_time && props.details.departure_time.split(':')[0]}
            onChange={(hour) => {
              props.onUpdateSearch({
                ...props.details,
                departure_time: `${Tools.zeroPad(hour)}:00:00`
              })
            }}
          />

          <div className="hSeparator"></div>

          <CabinClassField
            availableClasses={availableCabinClasses}
            cabinClass={props.details.cabin_class}
            onChange={(cabinClass) => {
              props.onUpdateSearch({ ...props.details, cabin_class: cabinClass })
            }}
          />

          <StopoverField
            stopovers={props.details.max_stops}
            onChange={(newStops) => {
              props.onUpdateSearch({ ...props.details, max_stops: parseInt(newStops) })
            }}
          />
        </div>

        {/* DESTINATION AND RETURN DETAILS ========================================== */}

        <div style={{ display: 'flex' }}>
          <AirportSearchField
            label="Destination"
            iata={props.details.destination}
            onChange={(iata) => {
              props.onUpdateSearch({ ...props.details, destination: iata })
            }}
          />

          <div className="hSeparator"></div>

          <FlightDateField
            isOpen={isReturnPickerOpen}
            whenOpened={() => {
              setReturnPickerOpen(true)
              setDeparturePickerOpen(false)
            }}
            whenClosed={() => {
              setReturnPickerOpen(false)
            }}
            label="Return Date"
            minimumDate={Tools.shortFormatDateToFullDate(props.details.departure_date)}
            date={
              Tools.shortFormatDateToFullDate(props.details.return_date) <
              Tools.shortFormatDateToFullDate(props.details.departure_date)
                ? props.details.departure_date
                : props.details.return_date
            }
            onChange={(newDate) => {
              props.onUpdateSearch({ ...props.details, return_date: newDate })
            }}
          />

          <FlightTimeField
            hour={props.details.return_time && props.details.return_time.split(':')[0]}
            onChange={(hour) => {
              props.onUpdateSearch({
                ...props.details,
                return_time: `${Tools.zeroPad(hour)}:00:00`
              })
            }}
          />

          {/*
          <div className="hSeparator"></div>
          <CabinClassField
            availableClasses={availableCabinClasses}
            cabinClass={props.details.cabin_class}
            onChange={(cabinClass)=>{ props.onUpdateSearch({...props.details, cabin_class: cabinClass}) }}
          />
          <StopoverField 
            stopovers={props.details.max_stops}
            onChange={(newStops)=>{ props.onUpdateSearch({...props.details, max_stops: parseInt(newStops)}) }}
          /> */}
        </div>
      </div>

      <div style={{ textAlign: 'right', marginTop: 10 }}>
        {props.isLoading && <Loading />}
        {!props.isLoading && (
          <SearchButton
            onClick={() => {
              props.onSearch()
            }}
          />
        )}
      </div>
    </div>
  )
}
