import { Popover } from 'react-tiny-popover'

export default function ClimateOptionActionPopover(props) {
  return (
    <Popover
      isOpen={props.isOpen}
      positions={['top', 'left']}
      align={'end'}
      onClickOutside={props.onClose}
      content={
        <div
          style={{
            backgroundColor: 'white',
            padding: 15,
            boxShadow: '0 2px 10px 0 rgba(6,6,93,0.2)',
            borderRadius: 4,
            minWidth: 180,
            margin: 15,
            userSelect: 'none'
          }}>
          <div
            style={{
              fontWeight: 'bold',
              fontSize: 14,
              letterSpacing: '0.78px',
              padding: '5px 5px'
            }}>
            Select Flight as
          </div>
          <div style={{ borderBottom: '1px solid #ddd', paddingTop: 5, marginBottom: 5 }}></div>
          <div
            onClick={() => {
              props.onSelect('best_match')
              props.onClose()
            }}
            className="gray-hover"
            style={{ fontSize: 14, padding: '12px 5px', cursor: 'pointer', borderRadius: 5 }}>
            Best Match
          </div>
          <div
            onClick={() => {
              props.onSelect('better_match')
              props.onClose()
            }}
            className="gray-hover"
            style={{ fontSize: 14, padding: '12px 5px', cursor: 'pointer', borderRadius: 5 }}>
            Better Match
          </div>
          <div
            onClick={() => {
              props.onSelect('max_savings')
              props.onClose()
            }}
            className="gray-hover"
            style={{ fontSize: 14, padding: '12px 5px', cursor: 'pointer', borderRadius: 5 }}>
            Max Savings
          </div>
        </div>
      }>
      <div
        style={{
          width: 32,
          height: 32,
          textAlign: 'center',
          backgroundColor: '#06065D',
          borderRadius: 32,
          cursor: 'pointer',
          display: 'inline-block'
        }}
        onClick={props.onOpen}>
        <img style={{ width: 16, height: 16, marginTop: 8 }} src="/dots.svg" alt="More" />
      </div>
    </Popover>
  )
}
