import { useEffect, useRef } from "react";

export const Tools = {
  zeroPad: (i) => {
    return i.toString().length < 2 ? `0${i}` : `${i}`
  },

  formatNumber: (n) => {
    return new Intl.NumberFormat('de-CH').format(n)
  },

  getShortFormatDate: (d) => {
    return (
      d.getFullYear() + '-' + Tools.zeroPad(d.getMonth() + 1) + '-' + Tools.zeroPad(d.getDate())
    )
  },

  formatCabinClass: (cabin_class) => {
    var c = cabin_class.replace("PREMIUM_ECONOMY", "Premium Economy"); // Economy and Business can be taken as-is
    return Tools.capitalizeCase(c);
  },

  getHumanReadableDate: (d) => {
    let months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ]
    return Tools.zeroPad(d.getDate()) + ' ' + months[d.getMonth()] + ' ' + d.getFullYear()
  },

  shortFormatDateToFullDate: (d) => {
    if (d.length !== 10) return new Date() // Or null?
    let incomingYear = d.split('-')[0]
    let incomingMonth = d.split('-')[1]
    let incomingDay = d.split('-')[2]
    return new Date(incomingYear, incomingMonth - 1, incomingDay, 0, 0, 0, 0)
  },

  flightTimeFromDate: (d) => {
    return d.getHours() + ':' + Tools.zeroPad(d.getMinutes())
  },

  parseServerTime: (d) => {
    let [date, time] = d.split('T')
    let incomingYear = date.split('-')[0]
    let incomingMonth = date.split('-')[1]
    let incomingDay = date.split('-')[2]
    let incomingHour = time.split(':')[0]
    let incomingMinute = time.split(':')[1]
    return new Date(
      incomingYear,
      incomingMonth - 1,
      incomingDay,
      incomingHour,
      incomingMinute,
      0,
      0
    )
  },

  minutesToDuration: (minutes) => {
    var h = Math.floor(minutes / 60)
    var m = minutes % 60
    if (h === 0) return `${m}m`
    return `${h}h ${m}m`
  },

  percentageImprovement: (a, base) => {
    if (base === 0) return 0;
    return -1 * (100 - Math.round((100 * a) / base))
  },

  capitalizeCase: (word) => {
    return word
      .toLowerCase()
      .split(' ')
      .map((e) => e.charAt(0).toUpperCase() + e.substring(1))
      .join(' ')
  },

  reduceCarriers: (segments) => {
    // This function tries to reduce the double-mentioning of carriers.
    // So instead of "Swiss, Swiss" it would reduce it to just "Swiss".
    let reducedCarrierList = []
    segments.forEach((segment, i) => {
      let carrier = Tools.figureOutCarrier(segment)
      if (reducedCarrierList.indexOf(carrier) < 0) reducedCarrierList.push(carrier)
    })
    return reducedCarrierList
  },

  figureOutCarrier: (segment) => {
    return segment.operating_carrier_code ? segment.operating_carrier_code : segment.carrier_code
  },

  translateLocationWithDictionary: (term, dictionary) => {
    try {
      return dictionary.locations[term].additional.detailed_name
    } catch (e) {
      return term
    }
  },

  translateCarrierWithDictionary: (term, dictionary) => {
    try {
      return dictionary.carriers[term]
    } catch (e) {
      return term
    }
  },

  renderStopOvers: (segments) => {
    if (segments.length <= 1) return 'Non-Stop'
    return `${segments.length - 1}: ${segments
      .slice(0, segments.length - 1)
      .map((segment) => segment.destination)
      .join(', ')}`
  },

  /* This helps us use timers in components and handles unmounting/deconstructing automatically */
  useInterval: (callback, delay) => {
    const savedCallback = useRef();
  
    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }
}
