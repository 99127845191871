import './ImageButton.css'
export default function ImageButton(props) {
  return (
    <button
      type={props.type || 'button'}
      className={`image-button-component ${props.disabled ? 'image-button-disabled' : ''}`}
      onClick={() => {
        if (props.onClick) {
          if (!props.disabled) {
            props.onClick()
          }
        }
      }}>
      <img src={props.src} alt="Button" style={{ width: 10 }} />
    </button>
  )
}
