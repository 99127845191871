import { useState } from 'react'

import Button from '../../components/Button'
import TextInput from '../../components/TextInput'

import useStore from '../../store'

export const CognitoPasswordUpdate = (props) => {
  // Global state
  const cognitoSetInitialPassword = useStore((state) => state.cognitoSetInitialPassword)
  const awsUserObject = useStore((state) => state.awsUserObject)

  // Local state
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  // Actions
  const updatePassword = async (e) => {
    e.preventDefault()
    cognitoSetInitialPassword(awsUserObject, email, password)
  }

  return (
    <div
      style={{ width: 400, marginLeft: 'auto', marginRight: 'auto', marginTop: 100, padding: 20 }}>
      <div style={{ textAlign: 'center', fontSize: 18, fontWeight: 'bold', marginBottom: 40 }}>
        ClimateSmartTravel
      </div>

      <form onSubmit={updatePassword}>
        Please set up your new account with a secure password.
        <br />
        <br />
        <TextInput
          type={'text'}
          name={'email'}
          value={email}
          placeholder="Your E-mail"
          onWhite={true}
          onChange={(d) => {
            setEmail(d)
          }}
        />
        <TextInput
          type={'password'}
          name={'password'}
          value={password}
          placeholder="Your Password"
          onWhite={true}
          onChange={(d) => {
            setPassword(d)
          }}
        />
        <br />
        <div style={{ textAlign: 'right' }}>
          <Button type="submit" text={'Sign in'} />
        </div>
      </form>
    </div>
  )
}
