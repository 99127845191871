import useStore from '../store'
import SidebarStatus from './SidebarStatus'
import './Sidebar.css'

export default function Sidebar(props) {
  const awsUserObject = useStore((state) => state.awsUserObject)
  const agencyClient = useStore((state) => state.agencyClient)
  const offerId = useStore((state) => state.agencyOfferId)
  const cognitoLogout = useStore((state) => state.cognitoLogout)

  return (
    <nav className="sidebar-container">
      <div className="sidebar-logo">
        <img src="/logo.png" alt="Logo" width="100%" />
      </div>
      <div className="sidebar-top-details">
        <div style={{ display: 'flex', marginBottom: 10 }}>
          <div style={{ width: 80 }}>Customer</div>
          <div style={{ flexGrow: 1 }}></div>
          <div style={{ fontWeight: 'bold' }}>{agencyClient.name}</div>
        </div>
        {offerId && (
          <div style={{ display: 'flex' }}>
            <div style={{ width: 80 }}>Offer ID</div>
            <div style={{ flexGrow: 1 }}></div>
            <div style={{ fontWeight: 'bold' }}>{offerId}</div>
          </div>
        )}
      </div>
      <div className="sidebar-status">
        <SidebarStatus state={props.stage || 0} />
      </div>
      <div style={{ flexGrow: 1 }}></div>
      <div className="sidebar-user">
        {awsUserObject && awsUserObject.attributes && awsUserObject.attributes.email}
      </div>
      <div className="sidebar-footer">
        <a href="https://climpact.ai" target="_blank" rel="noreferrer">
          climpact.ai
        </a>
        <br />
        <br />
        <a
          href="#bye"
          onClick={() => {
            cognitoLogout().then(() => {
              document.location.href = '/#bye'
            })
          }}>
          Log out
        </a>
        <br />
        <br />
        <br />
        ClimPact &copy; 2022
      </div>
    </nav>
  )
}
