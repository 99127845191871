import { useState } from 'react'
import { Popover } from 'react-tiny-popover'
import { Tools } from '../../helpers/Tools'
import ImageButton from '../ImageButton'
import TextInput from '../TextInput'

import './OfferingPricePopover.css'

export default function OfferingPricePopover(props) {
  const [localPrice, setLocalPrice] = useState(props.price)
  return (
    <Popover
      isOpen={props.isOpen}
      positions={['top', 'left']}
      align={'end'}
      onClickOutside={props.onClose}
      content={
        <div
          style={{
            backgroundColor: 'white',
            padding: 15,
            boxShadow: '0 2px 10px 0 rgba(6,6,93,0.2)',
            borderRadius: 4,
            minWidth: 180,
            marginBottom: 15
          }}>
          <div
            style={{
              fontWeight: 'bold',
              fontSize: 14,
              letterSpacing: '0.78px',
              padding: '5px 5px'
            }}>
            Edit Trip Fare
          </div>
          <div style={{ borderBottom: '1px solid #ddd', paddingTop: 5, marginBottom: 5 }}></div>
          <div style={{ display: 'flex', alignItem: 'center' }}>
            <div style={{ fontSize: 12, paddingTop: 15, paddingRight: 10 }}>{props.currency}</div>
            <div style={{ width: 80, marginRight: 10 }}>
              <TextInput
                autoFocus={true}
                type="number"
                onWhite={true}
                value={localPrice}
                onChange={(p) => {
                  if (parseFloat(p) < 100000 && parseFloat(p) > 0) {
                    setLocalPrice(p)
                  }
                }}
                textAlign={'right'}
                onEnter={() => {
                  props.onChange(localPrice)
                }}
              />
            </div>
            <div style={{ paddingTop: 5 }}>
              <ImageButton
                src="/white-check.svg"
                onClick={() => {
                  props.onChange(localPrice)
                }}
              />
            </div>
          </div>
        </div>
      }>
      <div>
        {props.priceImprovement !== null && (
          <div
            style={{
              color: props.priceImprovement < 0 ? '#0a0' : '#DD4155',
              fontWeight: 'bold'
            }}>
            {props.priceImprovement}%
          </div>
        )}
        <div className={'price-editor-input'} onClick={props.onOpen}>
          {/*<span style={{ fontSize: 10, marginRight: 5, color: '#555' }}>{props.currency}</span>*/}
          {Tools.formatNumber(props.price)}
        </div>
      </div>
    </Popover>
  )
}
