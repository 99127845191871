import './AirportSearchField.css'
export default function AirportSearchField(props) {
  return (
    <div>
      <div className="airport-search-label">{props.label || ''}</div>
      <input
        className="airport-search-input"
        type={props.type || 'text'}
        placeholder={props.placeholder}
        value={props.iata}
        onChange={(e) => {
          props.onChange && props.onChange(e.target.value.toUpperCase())
        }}
      />
    </div>
  )
}
