import { useEffect, useState } from 'react'
import useStore from './store'
import { User } from './helpers/User'
import BookingWizard from './pages/BookingWizard'
import { LobbyPage } from './pages/Lobby'
import { CognitoLoginPage } from './pages/CognitoLogin'
import Loading from './components/Loading'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheck, faSearch } from '@fortawesome/free-solid-svg-icons'

/* ---- AWS Amplify for Cognito User Authentication ---- */
import Amplify from 'aws-amplify'
Amplify.configure({
  aws_project_region: 'eu-central-1',
  aws_cognito_identity_pool_id: 'eu-central-1:7ba976d7-44ff-46f9-b869-1b606280afd3',
  aws_cognito_region: 'eu-central-1',
  aws_user_pools_id: 'eu-central-1_LQ4QPeGe6',
  aws_user_pools_web_client_id: '6o7r15rqdspba28fpjdol8esm0',
  oauth: {},
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: []
  },
  aws_cognito_verification_mechanisms: ['EMAIL']
})
/* ----------------------------------------------------- */

// This sets up our Font Awesome icons, so we can re-use them across the app.
library.add(faCheck, faSearch)

export default function App() {
  const cognitoRestoreUserSession = useStore((state) => state.cognitoRestoreUserSession)
  const isAuthenticated = useStore((state) => state.isAuthenticated)
  const agencyClient = useStore((state) => state.agencyClient)
  const travelerId = useStore((state) => state.travelerId)

  const [isLoading, setLoading] = useState(true)

  const previousWizardStage = useStore((state) => state.previousWizardStage)

  useEffect(() => {
    /*
      Since this is a single-page app, we need to hijack the BACK button and prevent it from leaving the page.
      This also helps us control the back behaviour in the wizard stages of the app 
    */
    window.history.pushState(null, null, document.URL)
    window.addEventListener('popstate', (e) => {
      e.preventDefault()
      e.stopPropagation()
      window.history.pushState(null, null, document.URL)
      previousWizardStage()
      return false
    })

    // Check if the user is authenticated...
    setLoading(true)
    User.get()
      .then((user) => {
        console.log('[OK] User Details found. Session valid: ', user)
        cognitoRestoreUserSession(user)
        setLoading(false)
      })
      .catch((error) => {
        console.log('[NO USER DETAILS] Session not found.')
        setLoading(false)
      })
  }, [cognitoRestoreUserSession, previousWizardStage])

  if (isLoading) return <Loading />

  // It all starts with a login if (isAuthenticated = false).
  // If that's passed, it shows a LOBBY screen, where you can select the agency and traveler ID.
  // If both are set, you move on to the WIZARD part, where you start searching for flights.

  return isAuthenticated ? (
    agencyClient && travelerId ? (
      <BookingWizard />
    ) : (
      <LobbyPage />
    )
  ) : (
    <CognitoLoginPage />
  )
}
