import { Fragment, useState } from 'react'
import { Buffer } from 'buffer';

import RadioButton from './RadioButton'

import './FlightBundleResult.css'
import { Tools } from '../helpers/Tools'
import StopoverPopover from './StopoverPopover'
import RouteLabel from './RouteLabel'
import SortIcon from './SortIcon'

export default function FlightBundleResult(props) {
  const [selectedFlightId, setSelectedFlightId] = useState(-1)
  const [departureSortDirection, setDepartureSortDirection] = useState(0) // 0 = no sort, -1 down, +1 up

  const toggleDepartureSort = () => {
    if (departureSortDirection === 0) setDepartureSortDirection(1)
    if (departureSortDirection === 1) setDepartureSortDirection(-1)
    if (departureSortDirection === -1) setDepartureSortDirection(0)
  }

  const sortResultsByDeparture = (results, sortDirection) => {
    if (sortDirection === 0) return results
    if (sortDirection === 1)
      return results
        .slice()
        .sort((a, b) =>
          Tools.parseServerTime(a.outward_itinerary.segments[0].departure_time) <
          Tools.parseServerTime(b.outward_itinerary.segments[0].departure_time)
            ? -1
            : 1
        )
    return results
      .slice()
      .sort((a, b) =>
        Tools.parseServerTime(a.outward_itinerary.segments[0].departure_time) >
        Tools.parseServerTime(b.outward_itinerary.segments[0].departure_time)
          ? -1
          : 1
      )
  }

  const updateFlightSelection = (flightId) => {
    setSelectedFlightId(flightId)
    props.onResultPicked(props.results.find((flight) => flight.id === flightId))
  }

  const renderDate = (d) => {
    let t = d.split('T')[1].split(':')[0] + ':' + d.split('T')[1].split(':')[1]
    return t
  }

  const isNextDay = (flight) => {
    let startDay = Tools.parseServerTime(flight.segments[0].departure_time)
    let arrivalDay = Tools.parseServerTime(flight.segments[flight.segments.length - 1].arrival_time)
    if (startDay.getDate() !== arrivalDay.getDate()) return true
    return false
  }

  return (
    <div className="flight-result-list">
      <table cellPadding={0} cellSpacing={0}>
        <thead>
          <tr>
            <th></th>
            <th>Carrier</th>
            <th>Cabin Class</th>
            <th>Destination</th>
            <th
              onClick={toggleDepartureSort}
              className="sort-table-header-column"
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
              Departure <SortIcon direction={departureSortDirection} />
            </th>
            <th>Arrival</th>
            <th>Duration</th>
            <th>Stops</th>
          </tr>
        </thead>
        <tbody>
          {sortResultsByDeparture(props.results, departureSortDirection).map((result, i) => {
            let outboundFlight = result.outward_itinerary
            let returnFlight = result.return_itinerary
            return (
              <Fragment key={`result_outbound_${Buffer.from(JSON.stringify(result)).toString('base64')}`}>
                <tr
                  className="flight-result-outbound"
                  onClick={() => {
                    updateFlightSelection(result.id)
                  }}>
                  <td rowSpan={2} style={{ paddingLeft: 15 }}>
                    <RadioButton
                      selected={selectedFlightId === result.id}
                      onClick={() => {
                        updateFlightSelection(result.id)
                      }}
                    />
                  </td>
                  <td>
                    {outboundFlight.segments
                      .map((segment) => {
                        return `${Tools.figureOutCarrier(segment)} ${segment.flight_number}`
                      })
                      .join(', ')}
                  </td>
                  <td>
                    {Tools.formatCabinClass(outboundFlight.cabin_class)}
                     (
                    {outboundFlight.segments.map((segment) => segment.booking_class).join(', ')})
                  </td>
                  <td>
                    <RouteLabel segments={outboundFlight.segments} />
                  </td>
                  <td>{renderDate(outboundFlight.segments[0].departure_time)}</td>
                  <td>
                    {renderDate(
                      outboundFlight.segments[outboundFlight.segments.length - 1].arrival_time
                    )}
                    {isNextDay(outboundFlight) && (
                      <div
                        style={{
                          display: 'inline-block',
                          backgroundColor: '#ddd',
                          borderRadius: 4,
                          padding: '2px 3px',
                          fontSize: 9,
                          position: 'relative',
                          top: -1,
                          marginLeft: 5
                        }}>
                        +1
                      </div>
                    )}
                  </td>
                  <td>{Tools.minutesToDuration(outboundFlight.duration)}</td>
                  <td>
                    {outboundFlight.segments.length === 1 ? (
                      'Non-Stop'
                    ) : (
                      <StopoverPopover segments={outboundFlight.segments} />
                    )}
                  </td>
                </tr>
                <tr
                  className="flight-result-return"
                  key={`result_return_${i}`}
                  onClick={() => {
                    updateFlightSelection(i)
                  }}>
                  {/* One TD is missing, as we're spanning vertically over two rows to fit the checkbox  */}
                  <td>
                    {returnFlight.segments
                      .map((segment) => {
                        return `${Tools.figureOutCarrier(segment)} ${segment.flight_number}`
                      })
                      .join(', ')}
                  </td>
                  <td>
                    {Tools.formatCabinClass(returnFlight.cabin_class)} (
                    {outboundFlight.segments.map((segment) => segment.booking_class).join(', ')})
                  </td>
                  <td>
                    <RouteLabel segments={returnFlight.segments} />
                  </td>
                  <td>{renderDate(returnFlight.segments[0].departure_time)}</td>
                  <td>
                    {renderDate(
                      returnFlight.segments[returnFlight.segments.length - 1].arrival_time
                    )}
                    {isNextDay(returnFlight) && (
                      <div
                        style={{
                          display: 'inline-block',
                          backgroundColor: '#ddd',
                          borderRadius: 4,
                          padding: '2px 3px',
                          fontSize: 9,
                          position: 'relative',
                          top: -1,
                          marginLeft: 5
                        }}>
                        +1
                      </div>
                    )}
                  </td>
                  <td>{Tools.minutesToDuration(returnFlight.duration)}</td>
                  <td>
                    {returnFlight.segments.length === 1 ? (
                      'Non-Stop'
                    ) : (
                      <StopoverPopover segments={returnFlight.segments} />
                    )}
                  </td>
                </tr>
                <tr className="flight-bundle-row-separator">
                  <td colSpan={8}></td>
                </tr>
              </Fragment>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
