import './StopoverField.css'
export default function StopoverField(props) {
  return (
    <div>
      <div className="stopover-label">{props.label || 'Max Stops'}</div>
      <select
        className="stopover-component"
        onChange={(e) => {
          props.onChange(e.target.value)
        }}
        value={props.stopovers}>
        <option value={0}>Non-Stop</option>
        <option value={1}>1 Stop</option>
        <option value={2}>2 Stops</option>
        <option value={3}>3 Stops</option>
      </select>
    </div>
  )
}
