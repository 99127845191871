import { Tools } from '../helpers/Tools'

export default function TripSummaryBar(props) {
  if (!props.trip) return null
  return (
    <div
      style={{
        display: 'flex',
        backgroundColor: '#f0f0f5',
        padding: 15,
        marginBottom: 10,
        borderRadius: 10,
        width: 950,
        fontSize: 14,
        letterSpacing: '0.75px',
        fontWeight: 'bold',
        alignItems: 'center'
      }}>
      <img src="/departure.svg" width="20" alt="Departure" style={{ marginRight: 10 }} />
      {props.trip.outward_itinerary.segments[0].origin}
      <div>
        <div style={{ color: '#999', marginLeft: 20, marginRight: 10, marginTio: -5 }}>→</div>
        <div style={{ color: '#999', marginLeft: 20, marginRight: 10, marginTop: -9 }}>←</div>
      </div>
      <img
        src="/arrival.svg"
        width="20"
        alt="Arrival"
        style={{ marginRight: 10, marginLeft: 10 }}
      />
      {
        props.trip.outward_itinerary.segments[props.trip.outward_itinerary.segments.length - 1]
          .destination
      }
      <div style={{ borderLeft: '1px solid #ddd', marginLeft: 20, marginRight: 20 }}></div>
      {Tools.getHumanReadableDate(
        Tools.parseServerTime(props.trip.outward_itinerary.segments[0].departure_time)
      )}
      <span style={{ marginLeft: 10, marginRight: 10, color: '#999' }}>&mdash;</span>
      {Tools.getHumanReadableDate(
        Tools.parseServerTime(
          props.trip.return_itinerary.segments[0].departure_time
        )
      )}
    </div>
  )
}
